@import '~stylesheets/meta';

.wrap {
  position: absolute;
  top: $route-name-height;
  width: $route-name-width;
  padding-top: 0.01em;
  left: 0;
  text-align: center; }

.text {
  font-size: 0.09em; }
