$grid-gutter-width: 30px;
$grid-gutter-half: $grid-gutter-width / 2;

$dark-blue: #1b1636;
$dark-blue-hover: #4d4962;

$primary: $dark-blue;

$font-weight-bold: bold;
$headings-font-weight: $font-weight-bold;

$font-size-base: 1.25rem;
$font-size-lg: 1.5rem;
$font-size-sm: 0.8rem;

$font-family-sans-serif-default: system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: "Roboto", #{$font-family-sans-serif-default};

$route-name-height: 0.175em;
$route-name-width: 0.35em;

$controls-width: 0.2em;

$stop-zone-width: 0.15em;
