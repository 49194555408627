@import '~stylesheets/meta';

.wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba($white, 0.1);
  cursor: pointer; }

.control {
  width: $controls-width;
  height: $controls-width;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
