@import '~stylesheets/meta';

.wrap {
  background: $dark-blue;
  color: $white;
  padding: map-get($spacers, 3);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: $dark-blue-hover; } }

.arrow {
  position: relative;
  top: -0.15em; }
