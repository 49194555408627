@import '~stylesheets/meta';

.wrap {
  align-items: center;
  gap: 0.04em;
  position: absolute;
  bottom: 0;
  left: 0em;
  display: flex;
  margin-left: - $stop-zone-width / 4;
  margin-bottom: - $stop-zone-width / 4;
  transform-origin: $stop-zone-width / 2 $stop-zone-width / 2;

  &:nth-of-type(n+6) {
    display: none; }

  &:nth-of-type(2) {
    bottom: 0.195em;
    left: 0.065em;
    transform: scale(0.9); }

  &:nth-of-type(3) {
    bottom: 0.35em;
    left: 0.23em;
    transform: scale(0.8); }

  &:nth-of-type(4) {
    bottom: 0.463em;
    left: 0.4em;
    transform: scale(0.65); }

  &:nth-of-type(5) {
    bottom: 0.55em;
    left: 0.6em;
    transform: scale(0.5); } }

.zoneWrap {
  width: $stop-zone-width;
  height: $stop-zone-width;
  border-radius: 100%;
  background: $white;
  color: $dark-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  box-shadow: 0 0 0 0.02em $dark-blue; }

.zone {
  font-size: 0.075em; }

.label {
  font-size: 0.1em;

  .wrap:nth-of-type(n + 2) & {
    font-size: 0.08em; } }

.labelAndIcon {
  display: flex;
  align-items: center;
  gap: 0.04em;
  white-space: nowrap;

  .wrap:nth-of-type(3) & {
    transform: translate(0, 30%); }

  .wrap:nth-of-type(4) & {
    transform: translate(0, 30%); }

  .wrap:nth-of-type(n + 5) & {
    transform: translate(0, 40%); } }
