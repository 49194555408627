@import '~stylesheets/meta';

.table {
  margin: 0;

  td {
    vertical-align: middle; } }

.tdMinimal {
  width: 0;
  white-space: nowrap;
  opacity: 0.8;
  text-align: center; }

.tdForSignalIcon {
  @extend .tdMinimal;
  border-left: 0; }

.noBorderRight {
  border-right: 0; }
