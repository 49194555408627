@import '~stylesheets/meta';
@import 'bootstrap/scss/bootstrap';

html {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  font-size: 100%; }

body {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  user-select: none; }

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 0 100%;
  position: relative;
  z-index: 1;
  min-height: 100%;
  background: $dark-blue; }

.App {
  padding: map-get($spacers, 4) map-get($spacers, 3);
  gap: map-get($spacers, 4);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: $white;
  position: relative; }

h1 a {
  text-decoration: none; }
