@import '~stylesheets/meta';

.wrap {
  background: $dark-blue;
  color: $white;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  inset: 0; }

.box {
  width: 100%;
  position: relative;
  max-height: 100%;

  // &::before
  //   content: ''
  //   padding-top: percentage(1620 / 2160)
 }  //   display: block

.header {
  display: flex;
  align-items: center;
  gap: 0.03em;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; }

.to {
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 0.02em; }

.toText {
  font-size: 0.075em;
  margin: 0; }

.nameWrap {
  margin: 0;
  line-height: 1;
  width: $route-name-width;
  height: $route-name-height;
  font-size: inherit;
  cursor: pointer; }

.nameLink {
  width: 100%;
  height: 100%;
  background: $white;
  color: $dark-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; }

.name {
  font-size: 0.16em;
  line-height: 1;
  white-space: nowrap; }

.stops {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: map-get($spacers, 2);
  overflow: hidden;
  position: absolute;
  top: $route-name-height;
  left: 0;
  right: 0;
  bottom: 0; }

.svgWrap {
  position: absolute;
  top: 0;
  left: $controls-width + 0.09em;
  right: 0;
  bottom: 0.07em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; }

.svgPositioner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%; }

.svg {
  width: auto;
  height: 100%;
  display: block;
  position: relative;
  left: 0.01em; }
